import ChevronDown from '@components/svgs/svgChevronDown';
import styles from '@styles/default-page-scss/hero-banner/_hero-banner-promo.module.scss';
import Image from 'next/image';
import { memo, useEffect, useRef, useState } from 'react';

type Props = {
    title: string;
    text: string;
    data: any;
};

const HeroBanner = memo(function HeroBanner({ title, text, data }: Props): JSX.Element {
    const hasLabel = data.length > 0;
    const mainRef = useRef<HTMLElement>(null);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isAutoPlay, setIsAutoPlay] = useState(true);
    const [touchPosition, setTouchPosition] = useState(null);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isAutoPlay) {
            interval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.length);
            }, 5000);
        }

        return () => clearInterval(interval);
    }, [isAutoPlay, data.length]);

    useEffect(() => {
        const handleAction = () => {
            setIsAutoPlay(false);
        };

        const currentRef = mainRef.current;
        if (currentRef) {
            currentRef.addEventListener('click', handleAction);
            currentRef.addEventListener('touchstart', handleAction);
            currentRef.addEventListener('touchend', handleAction);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('click', handleAction);
                currentRef.removeEventListener('touchstart', handleAction);
                currentRef.removeEventListener('touchend', handleAction);
            }
        };
    }, [touchPosition, data.length]);

    const handleTouchStart = (e: any) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
        // Disables Background Scrolling whilst the SideDrawer/Modal is open
    };

    const handleTouchMove = (e: any) => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > data.length) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.length);
        }

        if (diff < -data.length) {
            setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
        }

        setTouchPosition(null);
    };

    return (
        <section className={styles.carouselWrapper} ref={mainRef} tabIndex={-1}>
            {hasLabel && (
                <div className={styles.carouselContainer}>
                    <div className={styles.row}>
                        <div className={styles.arrow}>
                            <button
                                className={`${styles.carouselDot} ${0 === currentImageIndex ? styles.inactive : ''}`}
                                onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
                                    }
                                }}
                                style={{ marginRight: '10px', transform: 'rotate(180deg)' }}
                                aria-label="Previous image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                    <path
                                        d="M9.91553 18L16.0845 12L9.91553 6"
                                        strokeWidth="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className={styles.thumbnail}>
                            <a
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                href={data[currentImageIndex].tourlink}
                                className={styles.promoImage}
                                title={data[currentImageIndex].label}
                                data-event-action="user-action"
                                data-event-label={data[currentImageIndex].label}
                                target="_blank">
                                <Image
                                    unoptimized
                                    alt={data[currentImageIndex].label}
                                    src={data[currentImageIndex].image}
                                    width={150}
                                    height={60}
                                    layout={'responsive'}
                                    priority
                                />
                            </a>
                        </div>
                        <div className={styles.arrow}>
                            <button
                                className={`${styles.carouselDot} ${currentImageIndex + 1 === data.length ? styles.inactive : ''}`}
                                onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.length)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.length);
                                    }
                                }}
                                aria-label="Next image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                    <path
                                        d="M9.91553 18L16.0845 12L9.91553 6"
                                        strokeWidth="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className={styles.carouselControls}>
                        {data.map((_, index) => (
                            <button
                                key={index}
                                className={`${styles.carouselDot} ${index === currentImageIndex ? styles.active : ''}`}
                                onClick={() => setCurrentImageIndex(index)}
                            />
                        ))}
                    </div>
                </div>
            )}
            <div className={hasLabel ? styles.heading : styles.headingOnly}>
                <h1 className={styles.title}>{title}</h1>
                <hr />
                <p className={styles.description}>{text}</p>
                <div className={styles.readMoreSection}>
                    <a href="#intro-content" className={styles.readMoreButton}>
                        Read More
                    </a>
                    <ChevronDown color="#216600" height={11} width={11} />
                </div>
            </div>
        </section>
    );
});

export default HeroBanner;
