import styles from '@styles/default-page-scss/list-cards/_list.module.scss';
import { useRouter } from 'next/router';
import React from 'react';

import { CardDataArrayType } from '../../../lib/types/cardProps';
import { holidayProps } from '../../../lib/types/holidayProps';
import { paginationLimit } from '../../../lib/web/config';
import Card from '../common/card';
import Carousel from '../default/carousel';

interface Props {
    latestReviews: CardDataArrayType;
    holiday: holidayProps;
    categoryCarousel: any;
    popularReviewsCarousel: any;
}

const LatestReviews: React.FC<Props> = ({ latestReviews, holiday, categoryCarousel, popularReviewsCarousel }) => {
    const router = useRouter();

    return (
        <>
            {router.query.page ? '' : <h2 className={styles.titleLatest}> Latest Reviews</h2>}

            <section className={styles.listSection}>
                <div className={styles.allCards}>
                    <div className={styles.cardList}>
                        {latestReviews.map((card, cIndex) =>
                            cIndex !== Number(paginationLimit) / 2 ? (
                                <div className={styles.cardDiv} key={`latestReviewsCard-${cIndex}`}>
                                    <Card site={card} holiday={holiday} index={cIndex} pageTag={'default'} />
                                </div>
                            ) : (
                                <>
                                    {/* Best Porn Sites by Category Section*/}
                                    {router.query.page ? '' : <Carousel type="card" items={categoryCarousel} />}

                                    {/* Most Popular Porn Reviews Section */}
                                    {router.query.page ? '' : <Carousel type="tag" items={popularReviewsCarousel} />}
                                    <div className={styles.cardDiv} key={`latestReviewsCard-${cIndex}`}>
                                        <Card site={card} holiday={holiday} index={cIndex} pageTag={'default'} />
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default LatestReviews;
